import { includedFileTypes } from "components/Business/Settings/FileManager/CustomIconSet";
import VatomInc from "./index";
import { RequestService } from "utils/VatomIncApi"
import { Business } from "@material-ui/icons";

export default class VatomIncFileSystem {
	constructor(private varius: typeof VatomInc) {}

	public async getLoginFile(businessId: string): Promise<any> {
		// We only do the request when we already have a prefix.
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${businessId}/login`,
			isAuthed: true,
			needErrorMessage: true
		});
		
		if (!!res.body?.error && res.body?.error?.code === "no_login-file_found") {
			return null
		}

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		return res.body;	
	}
	
	public async getClientLoginFile(businessId:string,clientId: string): Promise<any> {
		// We only do the request when we already have a prefix.
		const EXPECTED_STATUS_CODE = 200;
		const res = await this.varius.get({
			path: `/b/${businessId}/clients/${clientId}/login`,
			isAuthed: true,
			needErrorMessage: true
		});
		
		if (!!res.body?.error && res.body?.error?.code === "no_login-file_found") {
			return null
		}

		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		return res.body;	
	}

	public async putLoginFile(businessId: string, content: object|null): Promise<any> {
		// We only do the request when we already have a prefix.
		const EXPECTED_STATUS_CODE = 204;
		const res = await this.varius.put({
			path: `/b/${businessId}/login`,
			isAuthed: true,
			needErrorMessage: true,
			body: {
				content
			}
		});
		
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		return res.body;	
	}
	public async putClientLoginFile(businessId: string,clientId:string, content: object|null): Promise<any> {
		// We only do the request when we already have a prefix.
		const EXPECTED_STATUS_CODE = 204;
		const res = await this.varius.put({
			path: `/b/${businessId}/clients/${clientId}/login`,
			isAuthed: true,
			needErrorMessage: true,
			body: {
				content
			}
		});
		
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		return res.body;	
	}


	public async getBucketFilesystem(prefix: string, businessId?: string): Promise<any> {

		const EXPECTED_STATUS_CODE = 200;
		let prefixUrl = "/";
		if(prefix.endsWith("/")) {
			prefixUrl = prefixUrl.substring(0, prefixUrl.length-1 );
		}

		// We check the businessId it's not duplicated.
		if(businessId !== undefined && prefix.includes(businessId)) prefixUrl = prefix.replace(businessId, "")

		const res = await this.varius.get({
			path: `/b/${businessId}/resources${prefixUrl}`,
			isAuthed: true,
			needErrorMessage: true,
			service: RequestService.Resources,
			headers: {"Cache-Control" : "Revalidate response"}
		});
		if (res.statusCode !== EXPECTED_STATUS_CODE) {
			throw new Error(res.body.error.message);
		}

		// Removing the businessId in the name
		// Frontend fix as this endpoint is used everywhere.
		const items = res.body.map((item => {
			const extension : string = item.isDir ? "folder" : item?.mimeType?.split("/")[1];
			const icon: string = includedFileTypes.includes(extension) ? extension : "default" // default icon if it is not included. Needs to be done here
			
			return {
				...item,
				name: item.name.replace(`${businessId}/`, ""),
				icon
			};
		}));
		return items;
	}
}
